import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Summary from "./eid/Summary";
import Spinner from "./shared/Spinner";

const Dashboard = React.lazy(() => import("./dashboard/Dashboard"));
const Testing = React.lazy(() => import("./eid/Testing"));
const DreamsSummary = React.lazy(() => import("./dreams/Summary"));
const GeneralDreams = React.lazy(() => import("./dreams/GeneralDreams"));
const OVCMuso = React.lazy(() => import("./muso/OVCMuso"));
const Muso = React.lazy(() => import("./muso/GeneralMuso"));
const Schooling = React.lazy(() => import("./schooling/OVCSchooling"));
const OVCDreams = React.lazy(() => import("./dreams/OVCServ"));
const OVCGardening = React.lazy(() => import("./gardening/OVCServ"));
const PTMEOVC = React.lazy(() => import("./ptme/OVCServ"));
const GeneralVBG = React.lazy(() => import("./vbg/GeneralVBG"));
const GeneralPtme = React.lazy(() => import("./ptme/GeneralPtme"));
const GeneralOVC = React.lazy(() => import("./ovc/GeneralOVC"));

function AppRoutes() {
    useEffect(() => {
        console.log("In the routes");
    }, []);
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route exact path="/dashboard" element={<Dashboard/>} />
                <Route path="/ptme/ovc" element={<PTMEOVC/>} />
                <Route path="/ptme/general" element={<GeneralPtme/>} />
                <Route path="/eid/summary" element={<Summary/>} />
                <Route path="/eid/testing" element={<Testing/>} />
                <Route path="/dreams/summary" element={<DreamsSummary/>} />
                <Route path="/dreams/ovc" element={<OVCDreams/>} />
                <Route path="/dreams/general" element={<GeneralDreams/>} />
                {/* Redirect / to /dashboard */}
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/muso/ovc" element={<OVCMuso/>} />
                <Route path="/muso/general" element={<Muso/>} />
                <Route path="/schooling/ovc" element={<Schooling/>} />
                <Route path="/gardening/ovc" element={<OVCGardening/>} />
                <Route path="/vbg/general" element={<GeneralVBG/>} />
                <Route path="/ovc/general" element={<GeneralOVC/>} />
                
                
            </Routes>
        </Suspense>
    );
    }

export default AppRoutes;