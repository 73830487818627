import React, { useEffect,useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
// import logo
import {ReactComponent as LogoMini} from '../../assets/images/logo-mini.svg';

function Sidebar() {
  const [state, setState] = useState({});
   const location = useLocation();

  const toggleMenuState = (menuState)=>{
    if (state[menuState]) {
      setState({[menuState] : false});
    } else if(Object.keys(state).length === 0) {
      setState({[menuState] : true});
    } else {
      Object.keys(state).forEach(i => {
         setState({[i]: false});
      });
      setState({[menuState] : true});
    }
  }

  

  const onRouteChanged=()=>{
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys( state).forEach(i => {
       setState({[i]: false});
    });
    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/eid', state: 'eidMenuOpen'},
      {path:'/ptme', state: 'ptmeMenuOpen'},
      {path:'/dreams', state: 'dreamsUiMenuOpen'},
      {path:'/muso', state: 'musoUiMenuOpen'},
      {path:'/vbg', state: 'vbgMenuOpen'},
      {path:'/ovc', state: 'ovcMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'}
    ];

    dropdownPaths.forEach((obj => {
      if ( isPathActive(obj.path)) {
         setState({[obj.state] : true})
      }
    }));
 
  }

const isPathActive= (path)=> {
    return location.pathname.startsWith(path);
  }

  useEffect(() => {
    onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, [location]);
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html"> <img src={require('./../../assets/images/logo.png')} alt="logo" /> </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><LogoMini />{/*<img src={logo_mini} alt="logo" />*/}</a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Manage Accounts</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Change Password</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Check Inbox</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Sign Out</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
      
          <li className={  isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title"><Trans>OVC Serv</Trans></span>
            </Link>
          </li>

          <li className={  isPathActive('/ptme') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.ptmeMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('ptmeMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>PTME</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.ptmeMenuOpen }>
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item"> <Link className={  isPathActive('/ptme/summary') ? 'nav-link active' : 'nav-link' } to="/dreams/summary"><Trans>Summary</Trans></Link></li> */}
                <li className="nav-item"> <Link className={  isPathActive('/ptme/general') ? 'nav-link active' : 'nav-link' } to="/ptme/general">General</Link></li>
                <li className="nav-item"> <Link className={  isPathActive('/ptme/ovc') ? 'nav-link active' : 'nav-link' } to="/ptme/ovc"><Trans>OVC</Trans></Link></li>
              </ul>
            </Collapse>
          </li>


          <li className={  isPathActive('/dreams') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.dreamsUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('dreamsUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>DREAMS</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.dreamsUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={  isPathActive('/dreams/summary') ? 'nav-link active' : 'nav-link' } to="/dreams/summary"><Trans>Summary</Trans></Link></li>
                <li className="nav-item"> <Link className={  isPathActive('/dreams/general') ? 'nav-link active' : 'nav-link' } to="/dreams/general"><Trans>Enrollement</Trans></Link></li>
                <li className="nav-item"> <Link className={  isPathActive('/dreams/ovc') ? 'nav-link active' : 'nav-link' } to="/dreams/ovc"><Trans>OVC</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={  isPathActive('/eid') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.eidMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('eidMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>EID</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.eidMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={  isPathActive('/eid/summary') ? 'nav-link active' : 'nav-link' } to="/eid/summary"><Trans>Summary</Trans></Link></li>
                <li className="nav-item"> <Link className={  isPathActive('/eid/testing') ? 'nav-link active' : 'nav-link' } to="/eid/testing"><Trans>Testing</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={  isPathActive('/vbg') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.vbgUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('vbgUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>VBG</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.vbgUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item"> <Link className={  isPathActive('/dreams/summary') ? 'nav-link active' : 'nav-link' } to="/dreams/summary"><Trans>Summary</Trans></Link></li> */}
                <li className="nav-item"> <Link className={  isPathActive('/vbg/general') ? 'nav-link active' : 'nav-link' } to="/vbg/general"><Trans>General</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={  isPathActive('/ovc') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.vbgUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('ovcMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>OEV</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.ovcMenuOpen }>
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item"> <Link className={  isPathActive('/dreams/summary') ? 'nav-link active' : 'nav-link' } to="/dreams/summary"><Trans>Summary</Trans></Link></li> */}
                <li className="nav-item"> <Link className={  isPathActive('/ovc/general') ? 'nav-link active' : 'nav-link' } to="/ovc/general"><Trans>General</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={  isPathActive('/muso') ? 'nav-item active' : 'nav-item' }>
            <div className={  state.musoUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () =>  toggleMenuState('musoUiMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title"><Trans>MUSO</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={  state.musoUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={  isPathActive('/muso/general') ? 'nav-link active' : 'nav-link' } to="/muso/general"><Trans>General</Trans></Link></li>
                <li className="nav-item"> <Link className={  isPathActive('/muso/ovc') ? 'nav-link active' : 'nav-link' } to="/muso/ovc"><Trans>OVC</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={  isPathActive('/schooling/ovc') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/schooling/ovc">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title"><Trans>Schooling</Trans></span>
            </Link>
          </li>
          {/* for gardening ovc */}
          <li className={  isPathActive('/gardening/ovc') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/gardening/ovc">
              <i className="mdi mdi-television menu-icon"></i>
              <span className='menu-title'><Trans>Gardening</Trans></span>
            </Link>
          </li>
        </ul>
      </nav>
    );

}

export default Sidebar;