import React, {useState} from 'react';
import Spinner from '../shared/Spinner';
import useSWR from 'swr';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Pie as PieChart} from 'react-chartjs-2';
import {useForm  } from 'react-hook-form';


ChartJS.register(ArcElement, Tooltip, Legend);

const fetcher = async (endpoint) => {
    try {
        const response = await fetch(endpoint);
        const json = await response.json();
        return json;
    } catch (error) {
        throw error;
    }
};
const Summary = () => {

    let eid_total_endpoint = `${process.env.REACT_APP_API_HOST}/eid/summary/filter/?`;

    const {register, handleSubmit, errors , watch} = useForm(
        // default values
        {
            year: undefined,
            quarter: undefined,
            network: undefined,
        }
    );

    const [endpoint, setEndpoint] = useState(eid_total_endpoint);

    React.useEffect(() => {
        //
        document.title = 'Caris EID Summary';
        const query_params = watch();
        let params =''; // to store query params

        // iterate over query params
        for (const [key, value] of Object.entries(query_params)) {
            // skip if value is undefined or equal to empty string
            if (value === undefined || value === '') {
                continue;
            }
            params += `&${key}=${value}`;
        }
        setEndpoint(eid_total_endpoint + `${params}`);
    }, [watch()]);

    const {data, error, isLoading} = useSWR(endpoint, fetcher);


    const onSubmit = (data) => {console.log(data)};

    if (isLoading) {
        return <Spinner/>;
    }

    if (error) {
        return <p>Something went wrong</p>;
    }

    const cols = Object.keys(data.summary[0]);
    const tranches = data.summary.map(row => row[cols[0]]).slice(0,2);
    const specimen_prelevement = data.summary.map(row => parseInt(row[cols[2]])).slice(0,2);
    console.log("Specimen prelevement", specimen_prelevement);
    const specimen_with_no_result = data.summary.map(row => parseInt(row[cols[6]])).slice(0,2);
    console.log("Tranches", tranches);

    const pieData =(data)=> ({
        labels: tranches,
         datasets: [
            { 
                labels: tranches,
                data: data,
         
                      backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                        fill: true,
                    },
                ] });
    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,

        title: {
            display: true,
            text: cols[2]
        },
        // Add legend options
        legend: {
            display: true,
            position: 'right',
            labels: {
                boxWidth: 20,
                fontColor: 'black',
            },
        },
    };

    return (
        <div>

                                
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Filter</h4>
                            {/* Row to create form */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="year">Year</label>
                                        <select className="form-control" id="year" {...register("year")}>
                                            {/* default option */}
                                            <option value="">All</option>
                                            {data.year_title.map((year, index) => (
                                                 <option value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* Quarter */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="quarter">Quarter</label>
                                        <select className="form-control" id="quarter" {...register("quarter")}>
                                            {/* default option */}
                                            <option value="">All</option>
                                            {data.quarter_title.map((quarter, index) => (
                                                    <option value={quarter}>{quarter}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* Network */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="network">Network</label>
                                        <select className="form-control" id="network" {...register("network")}>
                                            {/* default option */}
                                            <option value="">All</option>
                                            {data.network_title.map((network, index) => (
                                                    <option value={network}>{network}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Summary</h4>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        {cols.map((col, index) => (
                                            <th key={index}>{col}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { data.summary.map((row, index) => (
                                        <tr key={index}>
                                            {
                                                cols.map((col, index) => (
                                                    <td key={index}>{row[col]}</td>
                                                ))
                                            }
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{cols[2]}</h4>
                            <PieChart data={pieData(specimen_prelevement)} //options={pieOptions} 
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{cols[6]}</h4>
                            <PieChart data={pieData(specimen_with_no_result)} //options={pieOptions} 
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Summary;