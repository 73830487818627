import React, { useEffect, useState } from 'react';
import './App.scss';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import AppRoutes from './AppRoutes';
import { useLocation } from 'react-router-dom';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

function App({signOut, user}) {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const location = useLocation();
    const onRouteChanged= () => {
    console.log("ROUTE CHANGED");
    // const { i18n } = this.props;
    const body = document.querySelector('body');
    // if(location.pathname === '/layout/RtlLayout') {
    //   body.classList.add('rtl');
    //   i18n.changeLanguage('ar');
    // }
    // else {
    //   body.classList.remove('rtl')
    //   i18n.changeLanguage('en');
    // }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        console.log("FULL PAGE LAYOUT", isFullPageLayout);
        setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        setIsFullPageLayout(false)
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  useEffect(() => {
    onRouteChanged();
  }, [location]);
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(App);
